import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
import Badge from "../../components/_ui/Badge.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Pergola Planting: Climbing Vegetables`}</h1>
    <span className="text-metadata">Posted on 4th January 2019</span>
    <Badge mdxType="Badge" />
    <img src="/img/outdoor-living/pergola-planting-climbing-vegetables/Runner-bean-pods-RHS.jpg" alt="A group of runner bean pods ready for picking" />
    <p><strong parentName="p">{`There’s something very pleasing about the idea of strolling around the pergola, glass in hand, plucking vegetables at eye level.`}</strong></p>
    <p>{`So much more civilised than grubbing around on your knees in the potager. This is the goal of growing vegetables up your pergola, and a mighty fine one it is too. However, there’s a fair bit of ground work to do, a lot of patience and a fair bit of help required from Mother Nature before you can achieve your goal of swanning around harvesting your haul.`}</p>
    <p>{`Apart from the fact that climbing vegetables look beautiful wending their way up the woodwork of a pergola, it’s actually a very practical way of growing. Beans for example are so much more productive in their climbing form than dwarf varieties. They produce more pods and seem to escape the slug and snail rather better than diminutive types.`}</p>
    <h2>{`Climbing vegetables to consider:`}</h2>
    <h3>{`Beans`}</h3>
    <h4>{`Climbing French beans`}</h4>
    <p>{`There are so many varieties to choose from you can afford to be picky. Go for a variety that has a beautiful flower as well as a good yield of tasty beans. Or venture beyond the traditional green bean. Purple-podded beans, borlotti and the French favourite, yellow ‘beurre’ haricot varieties all look fabulous. Almost too pretty to pick.`}</p>
    <h4>{`Runner beans`}</h4>
    <p>{`Plant three or four around a pergola upright to guarantee good coverage. They’ll do well if you give them something extra to scramble up, like pea and bean netting secured to the uprights. Runners need copious amounts of water, especially if it’s dry when the pods are just forming.`}</p>
    <h4>{`Borlotti`}</h4>
    <p>{`Breathtakingly beautiful speckly pods as well as beans, these climbers deserve a place in any pergola. The beans can be eaten small and fresh from the pod, or left to grow large on the plant until the pods yellow and dry out, enabling to you store the dried beans for winter cooking.`}</p>
    <img src="/img/outdoor-living/pergola-planting-climbing-vegetables/french-bean-blue-lake-climbing_SR.jpg" alt="A French climbing bean blue lake, entwined around a wooden stake" />
    <h2>{`Bean varieties to try`}</h2>
    <h3>{`St George runner`}</h3>
    <p>{`St George runner bean has a bicoloured red and white flower and produces heavy yields of light green pods`}</p>
    <h3>{`French bean ’Blauhilde Climbing’`}</h3>
    <p>{`Gorgeous purple pods, and versatile in the kitchen - you can eat the whole pods spanking fresh from the plant, or leave a few to swell before you harvest them, shelling the pods for their beans.`}</p>
    <h3>{`French bean Climbing Blue Lake`}</h3>
    <p>{`Ideal for pergolas as it climbs to more than 2 metres, and its beans are slow to become tough and stringy. Delicate white flowers before the pods set are just another reason to grow this where you can see it close up.`}</p>
    <h3>{`Borlotto Lingua di Fuoco (Firetongue)`}</h3>
    <p>{`The pods of these borlotti are highly coloured with splashes of deep pink, which look fanastic against their backdrop of green foliage.`}</p>
    <img src="/img/outdoor-living/pergola-planting-climbing-vegetables/crown-prince-TandM.jpg" alt="" />
    <h2>{`Squashes`}</h2>
    <p>{`Even with smaller cultivars, these bad boys need a very solid support system in addition to the sturdy uprights of the pergola. To give them the best chance possible, only plant them out when all threat of frost has passed. Best to wait until mid-June, adding plenty of well-rotted manure to their pots to give them a boost. Water well and mulch to keep in the moisture. Tie in shoots to support wires as they begin to take off. When the fruits have formed, cut leaves away from around the gourds to encourage them to ripen.`}</p>
    <h2>{`Squash varieties to try`}</h2>
    <h3>{`Crown Prince F1 Hybrid (winter)`}</h3>
    <p>{`A good candidate because its fruits turn a delightful silvery blue/grey colour and shouldn’t weigh more than a bag of sugar. Holds the RHS Award of Garden Merit.`}</p>
    <h3>{`Pumpkin Munchkin`}</h3>
    <p>{`As the name suggests, this is a mini pumpkin but still packs a punch in terms of flavour.`}</p>
    <h3>{`Squash Tromboncino`}</h3>
    <p>{`Loves to climb and produces the most outlandish (some might say suggestive) looking fruits. The long, bulbous-ended ’trombone-shaped’ squash will dangle in profusion from the abundant foliage, growing to more than a metre long if you let them. Harvest before they become too big and put Aunty Ethel off her gin and tonic. Stores well once harvested.`}</p>
    <img src="/img/outdoor-living/pergola-planting-climbing-vegetables/squash-tromboncino-SR.jpg" alt="A squash tromboncino plant, heavy with ripe squashA ripe crown prince pumpkin, cut in half to reveal the inside" />
    <h3>{`Courgettes`}</h3>
    <p>{`One of the few downsides to growing courgettes in a veg plot or allotment is that if you turn your back for five minutes after heavy rainfall, you find yourself awash with more oversized fruits than you know what to do with. Marrow chutney anyone? Grow them up a pergola, however, and you can keep a much closer eye on them, harvesting when they’re still young and firm.`}</p>
    <p>{`Courgettes are happy to scurry up vertical supports, and as long as you plant them in a rich soil mix, keep them well watered and pick them regularly, they will produce tasty fruits throughout the summer.`}</p>
    <h3>{`Courgette Black Forest F1 hybrid`}</h3>
    <p>{`Perfect for growing in a pot and training up a pergola, this has a unique climbing habit. Tie in the long stems as they grow. The flowers are loved by bees and other pollinators.`}</p>
    <h3>{`Cucumbers`}</h3>
    <p>{`These are natural climbers and will reward you with fruits all summer long. Try an RHS Award of Garden Merit variety like Carmen F1 which is easy to train and highly productive. Keep picking to maintain productivity and remove any unsightly mildewy leaves.`}</p>
    <p>{`These are just a few ideas for making your pergola planting productive and pretty. Tempting though it may be, it’s important not to get carried away and treat your pergola as a substitute vegetable patch. Growing a few vegetable plants that you can admire for their looks and harvest can bring an added dimension to outdoor living, but works best when combined with other planting schemes. Annual climbers can be grown effectively in conjunction with climbing veg. Tried-and-tested combinations include sweet peas and French beans, small-fruiting cucumbers with Black-eyed Susan, and French beans with canary creeper. You’ll create a lovely tangle of fruit and flowers whose colours and forms compliment each other throughout the season. Enjoy!`}</p>
    <h4>{`Image credits and where to Buy`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Runner bean`}</strong>{` - RHS - `}<a parentName="li" {...{
          "href": "http://www.rhs.org.uk"
        }}>{`www.rhs.org.uk`}</a></li>
      <li parentName="ul"><strong parentName="li">{`French bean 'Blauhilde Climbing'`}</strong>{` - Sarah Raven `}<a parentName="li" {...{
          "href": "http://www.sarahraven.com/shop/french-bean-blauhilde-climbing.html"
        }}>{`http://www.sarahraven.com/shop/french-bean-blauhilde-climbing.html`}</a></li>
      <li parentName="ul"><strong parentName="li">{`French bean 'Blue Lake Climbing'`}</strong>{` - Sarah Raven `}<a parentName="li" {...{
          "href": "http://www.sarahraven.com/shop/french-bean-blue-lake-climbing.html"
        }}>{`http://www.sarahraven.com/shop/french-bean-blue-lake-climbing.html`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Runner bean 'St George'`}</strong>{` - Thompson & Morgan `}<a parentName="li" {...{
          "href": "http://www.thompson-morgan.com/vegetables/vegetable-seeds/pea-and-bean-seeds/runner-bean-st-george/4775TM"
        }}>{`http://www.thompson-morgan.com/vegetables/vegetable-seeds/pea-and-bean-seeds/runner-bean-st-george/4775TM`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Courgette 'Black Forest'`}</strong>{` - Thompson & Morgan `}<a parentName="li" {...{
          "href": "http://www.thompson-morgan.com/vegetables/vegetable-seeds/pumpkin-squash-and-courgette-seeds/courgette-black-forest-f1-hybrid/783TM"
        }}>{`http://www.thompson-morgan.com/vegetables/vegetable-seeds/pumpkin-squash-and-courgette-seeds/courgette-black-forest-f1-hybrid/783TM`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Pumpkin 'Munchkin'`}</strong>{` - Sarah Raven `}<a parentName="li" {...{
          "href": "http://www.sarahraven.com/shop/pumpkin-munchkin.html"
        }}>{`http://www.sarahraven.com/shop/pumpkin-munchkin.html`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Squash 'Crown Prince'`}</strong>{` - Thompson & Morgan `}<a parentName="li" {...{
          "href": "http://www.thompson-morgan.com/vegetables/vegetable-seeds/squash-crown-prince-f1-hybrid/tm01102TM"
        }}>{`http://www.thompson-morgan.com/vegetables/vegetable-seeds/squash-crown-prince-f1-hybrid/tm01102TM`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Squash 'Tromboncino'`}</strong>{` - Sarah Raven `}<a parentName="li" {...{
          "href": "http://www.sarahraven.com/shop/courgette-tromboncino.html"
        }}>{`http://www.sarahraven.com/shop/courgette-tromboncino.html`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Cucumber 'Carmen'`}</strong>{` - Thompson & Morgan `}<a parentName="li" {...{
          "href": "http://www.thompson-morgan.com/vegetables/vegetable-seeds/salad-seeds/cucumber-carmen-f1-hybrid/538TM"
        }}>{`http://www.thompson-morgan.com/vegetables/vegetable-seeds/salad-seeds/cucumber-carmen-f1-hybrid/538TM`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      